/* VARIABLES */

//GOOGLE - FONTS  
@import url('https://fonts.googleapis.com/css2?family=Dancing+Script&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Livvic:ital,wght@1,200&display=swap');


//@media
$media:(
  -mobileS:450px,
  -mobile:500px,
  -tabletS:730px,
  -tablet:930px,
  -notebookS:1150px,
  -notebook:1350px,
  -pc:1550px,
);


//Size
$size:(
  -sm:16px,
  -md:24px,
  -lg:32px,
  -xl:50px,
  -xxl:80px
);

//Weight
$weight:(
  -medium:500,
  -bold:700,
  -extrabold:900
);


//Colores
$colores:(
  -principal:#80ED99,
  -principal2:#BDB2FF,
  -secundario:#FFF,
  -terciario:#000
);

//Tipografia  
$title:'Dancing Script', cursive;
$text:'Livvic', sans-serif;

