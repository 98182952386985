@use '../abstracts/mixin.scss' as *;
@use '../abstracts/variables.scss' as *;


.title-cont{
  @include flex(center);
  @include title('-secundario','-terciario','Opiniones de mis pacientes',120%);
}


.slider{
  @include flex(center);
  height: 800px;
  margin-top: 200px;
  background-color: #D8E2DC;
  .const-slider{
    display: block;
    max-width: 60%;
    margin: auto;
    text-align: center;
  }

  .cont-name{
    padding-bottom: 30px;
    h3{
      font-size: map-get($size, -lg);
      font-family: $text;
      font-weight: map-get($weight, -extrabold);
      color: map-get($colores, -terciario);
      letter-spacing: 5px;
      text-align: center;
    }
  }

  .cont-testimony{
    p{
      font-size: map-get($size , -md);
      font-family: $text;
      font-weight: map-get($weight, -medium);
      color: map-get($colores , -terciario);
      margin: 50px 0 0 0!important;
    }
  }
}

.carousel-control-prev-icon,
.carousel-control-next-icon{
  background-color:map-get($colores, -terciario) !important ;
  border-radius: 10px;
}

.carousel-indicators{
  z-index: 1 !important;
  & [data-bs-target]{
    height: 30px !important;
    border-radius: 50%;
    background-color: map-get($colores, -terciario) !important;
  }
}




/* Queries */
@media (max-width:(map-get($media , -notebook))){
  .slider{
    .const-slider{
      max-width: 80%;
    }
  }
}


@media (max-width:(map-get($media , -notebookS))){
  .slider{
    .const-slider{
      max-width: 95%;
    }
  }
}


@media (max-width:(map-get($media , -tablet))){

  .title-cont{
    .title-test{
      padding-bottom: 50px !important;
    }
  }

  .carousel-control-prev-icon,
  .carousel-control-next-icon{
    display: none !important;
  }

  .carousel-indicators{
    background-color: #D8E2DC;
    width: 100%;
    margin: auto !important;
    padding: 20px !important;

    & [data-bs-target]{
      width: 20px !important;
      height: 20px !important;
      border-radius: 50%;
      background-color: map-get($colores, -terciario) !important;
    }
  }
}


@media (max-width:(map-get($media , -tabletS))){
  .title-cont{
    @include title('-secundario','-terciario','Opiniones de mis pacientes',100%);
  }
  
  .carousel-indicators{
    background-color: #D8E2DC;
    width: 100%;
    margin: auto !important;
  }
}


@media (max-width:(map-get($media , -mobile))){
  .slider{
    height: 700px;
  }

  .carousel-indicators{
    & [data-bs-target]{
      width: 15px !important;
      height: 15px !important;
    }
  }
}


