@use '../abstracts/variables.scss' as *;
@use '../abstracts/mixin.scss' as *;

.contacto{
  padding: 100px 0;
  background-color: map-get($colores, -principal);
  &-cont{
    .title-cont{
      @include flex(center);
      @include title('-principal','-terciario','Ponerse en Contacto',120%);
    }

    .cont-info-personal{
      @include flex(space-evenly);
      max-width: 80%;
      margin: auto;
      margin-top: 150px;
      background-color: map-get($colores, -secundario);
      border-radius: 10px;
      gap: 50px;

      div{
        padding: 30px 50px;
        text-align: center;

        i{
          font-size: map-get($size, -xl);
          color: map-get($colores, -principal2);
          margin-bottom: 20px;
        }

        p{
          font-size: map-get($size, -sm);
          color: map-get($colores, -terciario);
          font-family: $text;
          font-weight: map-get($weight, -extrabold);

        }
        
      }
    }

    .cont-form{
      display: block;
      max-width: 80%;
      margin: auto;
      margin-top: 100px;
      background-color: map-get($colores, -secundario);
      border-radius: 10px;
      padding: 100px;
      font-size: map-get($size, -sm);
      font-family: $text !important;
      font-weight: map-get($weight, -bold);

      .form-control{
        border: none;
        border-bottom: 2px solid map-get($colores, -principal2) !important;
        resize: none;
        font-weight: map-get($weight, -extrabold);


        &:focus{
          box-shadow: none;
        }
      }

      textarea{
        height: 130px;
      }

      .btn{
        position: relative;
        background-color: transparent;
        color: map-get($colores, -terciario);
        border: 2px solid map-get($colores, -terciario);
        font-size: map-get($size, -sm);
        font-family: $text;
        font-weight: map-get($weight, -extrabold);
        padding: 10px 50px;

        .bg-btn{
          @include flex(center);
          position: absolute;
          width: 40px;
          height: 44px;
          left: 0;
          bottom: 0;
          background-color: map-get($colores, -secundario);
          overflow: hidden;
          transition: all .4s ease;

          i{
            font-size: map-get($size, -md);
            color: map-get($colores, -principal2);
            transition: transform 1s ease; 
          }
          span{
            color: map-get($colores, -terciario);
            overflow: hidden;
            opacity: 0;
            transition: opacity .2s ease; 
          }

        }

        
        &:hover .bg-btn{
          width: 100%;
          height:  100%;
          border-radius: 0%;
          color: #fff !important;
          background-color: map-get($colores, -principal);
          i{
            color: map-get($colores, -terciario);
            transform: translateX(30px);
          }
          span{
            opacity: 1;
          }
        }
        
      }
    }
  }
}


/* Queries */
@media (max-width:(map-get($media , -tablet))){
  .contacto{
    &-cont{
      .title-cont{
        .title-contacto{
          padding-bottom: 50px !important;
        }
      }
      .cont-info-personal{
        @include flex(space-between);
        max-width: 100%;
        margin-left: 20px;
        margin-right: 20px;
  
        div{
          padding: 30px;
          
        }
      }

      .cont-form{
        display: block;
        max-width: 100%;
        margin: 100px 20px auto 20px;
        padding: 50px;

      }
    }
  }
  
}


@media (max-width:(map-get($media , -tabletS))){
  .contacto{
    &-cont{
      .cont-info-personal{
        flex-wrap: wrap;
        max-width: 50%;
        margin: auto;
        margin-top: 100px;
  
        div{
          width: 100%;
        }
      }
    }
  }
}


@media (max-width:(map-get($media , -mobile))){
  .contacto{
    &-cont{
      .title-cont{
        @include title('-principal','-terciario','Ponerse en Contacto',100%);
      }
    }
  }
}
