@use './variables.scss' as *;

@mixin flex($value-js){
  display: flex;
  justify-content: $value-js;
  align-items: center;
};

@mixin button($bg-color,$txt-color,$font-sz){
  &:hover button:before,
  &:hover button:after{
    width: 100%;
  }

    button{
      position: relative;
      background-color: map-get($colores, $bg-color);
      padding: 20px;
      border-radius: 10px;
      border: none;
      overflow: hidden;
      color: map-get($colores, $txt-color);
      font-family: $text;
      font-size: map-get($size , $font-sz);
      cursor: pointer;
      text-transform: uppercase;
      font-weight: map-get($weight, -bold);
      transition: all .6s ease;

      &:before,
      &:after{
        content: '';
        position: absolute;
        top: 0;
        left: 0px;
        width: 0px;
        height: 3px;
        background: map-get($colores, $bg-color);
        transition: all 1s ease;
      }

      &::after{
        top: inherit;
        left: inherit;
        bottom: 0;
        right: 0;
      }

      &:hover{
        background: transparent;
        /*color: map-get($colores, $bg-color); */
      }
      
    }
}

@mixin title($bg-color,$txt-color,$value-cont,$width-value){
  h2{
    position: relative;
    font-size: map-get($size, -xxl);
    font-family: $text;
    font-weight: map-get($weight, -extrabold);
    color: map-get($colores, $txt-color);
    letter-spacing: 5px;
    text-align: center;

    &:before{
      content: "";
      position:absolute;
      width: $width-value;
      bottom: -35px;
      left: 50%;
      height: 5px;
      background:map-get($colores, $txt-color);
      transform: translateX(-50%);
    }


    &:after{
      content: $value-cont;
      width: 80%;
      font-family: $title;
      position: absolute;
      bottom: -45px;
      left: 50%;
      font-size: 24px;
      padding: 3px;
      color: map-get($colores, $txt-color);
      transform: translateX(-50%);
      background-color: map-get($colores, $bg-color);
    }
  }
}
