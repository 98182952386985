@use '../abstracts/mixin.scss' as *;
@use '../abstracts/variables.scss' as *;

.inicio{
  @include flex(center);

  &-cont{
    display: grid;
    justify-content: center;
    align-content: center;
    gap: 50px;
    text-align: center;

    .incio-title{
      font-size: 150px;
      font-family: $title;
      font-weight: map-get($weight, -medium);
    }

    .title-cont{
      @include flex(center);
      @include title('-secundario','-terciario','¿Quién soy?',120%);
      margin-top: 230px;

      .title{
        margin-bottom: 50px !important;
      }
    }

    .p-cont{
      display: block;
      max-width: 80%;
      margin:  auto;

      p{
        font-size: map-get($size , -lg);
        font-family: $text;
        font-weight: map-get($weight, -medium);
        color: map-get($colores , -terciario);
        margin:70px 0 50px 0 !important;
      }
    }
    
    .btn-cont{
      @include button('-principal','-terciario','-md');
    }
  }
}



.trabajo{
  padding: 50px 0;
  background-color: map-get($colores, -principal);
  &-cont{
    @include flex(space-evenly);
    max-width: 80%;
    margin:  auto;
    background-color: map-get($colores, -principal);
    padding: 40px 0 60px 0;

    .img-cont{
      img{
        width: 300px;
        height: auto;
        border: 20px solid map-get($colores, -terciario);
        box-shadow: 0px 0px 26px 24px rgba(0,0,0,0.3);
      }
    }

    .text-cont{
      display: grid;
      justify-content: center;
      align-content: center;
      gap: 60px;
      text-align: center;

      .title-cont{
        width: fit-content;
        margin: auto;
        .title-trabajo{
          font-size: map-get($size, -xxl);
          font-family: $text;
          font-weight: map-get($weight, -extrabold);
          color: map-get($colores, -terciario);
          border-bottom: 4px solid map-get($colores, -terciario);
          letter-spacing: 5px;
        }
      }


      .p-cont-tb{
        display: block;
        max-width: 80%;
        margin:  auto;
        p{
          font-size: map-get($size , -md);
          font-family: $text;
          font-weight: map-get($weight, -medium);
          color: map-get($colores , -terciario);
        }
      }

      .btn-cont-tb{
        @include button('-principal2','-terciario','-md');
      }
    }

   
  }
}


/* Queries */
@media (max-width:(map-get($media , -notebook))){
  .trabajo{
    &-cont{
      flex-wrap: wrap;
  
      .text-cont{
        .title-cont{
          .title-trabajo{
            margin-top: 100px !important;
          }
        }
      }
    }
  }
}

@media (max-width:(map-get($media , -notebookS))){
  .trabajo{
    &-cont{
      .text-cont{
        .title-cont{
          .title-trabajo{
            font-size: map-get($size, -xl);
          }
        }
      }
    }
  }
}

@media (max-width:(map-get($media , -tablet))){
  .inicio{
    &-cont{
      .incio-title{
        font-size: 100px;
      }
      .p-cont{
        max-width: 90%;
        p{
          font-size: map-get($size , -md);
          margin:0px 0 30px 0 !important;
        }
      }

      .btn-cont{
        @include button('-principal','-terciario','-sm');
      }
    }
  }


  .trabajo{
    &-cont{
      .text-cont{
        .btn-cont-tb{
          @include button('-secundario','-terciario','-sm');
        }
      }
    }
  }
  
}



@media (max-width:(map-get($media , -tabletS))){

  .trabajo{
    &-cont{
      @include flex(space-evenly);
      max-width: 100%;
  
      .img-cont{
        img{
          width: 200px;
          border: 10px solid map-get($colores, -terciario);
        }
      }
  
      .text-cont{
        .title-cont{
          .title-trabajo{
            letter-spacing: 3px;
          }
        }
  
        .p-cont-tb{
          max-width: 95%;
        }
      }

    }
  }
}


@media (max-width:(map-get($media , -mobile))){

  .trabajo{
    &-cont{
      flex-wrap: wrap;
  
      .text-cont{
        .title-cont{
          .title-trabajo{
            font-size: map-get($size , -lg);
          }
        }
      }
    }
  }
}

