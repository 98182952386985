@use '../abstracts/mixin.scss' as *;
@use '../abstracts/variables.scss' as *;

.servicios{
  @include flex(center);
  padding: 300px 0;

  &-cont{
    .title-cont{
      @include flex(center);
      @include title('-secundario','-terciario','Mis Servicios',120%);
    }
  

    .items-cont{
      display: block;
      max-width: 80%;
      margin: 150px auto 150px auto;

      .item{
        background-color: map-get($colores, -principal2);
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
        margin: 50px 0 0 0;
        padding: 50px;

        .title-item{
          position: relative;
          font-size: map-get($size, -lg);
          font-family: $text;
          font-weight: map-get($weight, -bold);
          color: map-get($colores, -terciario);
          text-align: center
        }

        .cont-info{
          @include flex(space-evenly);
          gap: 50px;

          img{
            margin: 50px auto 50px auto;
            width: 250px;
            height: 250px;
            border-radius: 20px;
            border: 10px solid map-get($colores, -terciario);
       
          }

          h5{
            font-size: map-get($size , -md);
            font-family: $text;
            font-weight: map-get($weight, -extrabold);
            color: map-get($colores , -terciario);
            margin-bottom: 15px;
          }

          p{
            font-size: map-get($size , -md);
            font-family: $text;
            font-weight: map-get($weight, -medium);
            color: map-get($colores , -terciario);
          }

          .btn-verMas{
            font-family: $text;
            background-color: map-get($colores , -principal);
            color: map-get($colores , -terciario);
            font-weight: map-get($weight, -extrabold);
            border: 2px solid map-get($colores , -principal);
            margin-top: 20px;
            transition:border .3s ease,
            background-color .3s ease;

            &:hover{
              background-color: transparent;
              border: 2px solid map-get($colores , -principal) ;
            }
          }
        }

        
      }
      .btn-consulta{
        width:100%;
        text-align: center;
        background-color: map-get($colores, -principal);
        border-bottom-left-radius: 20px;
        border-bottom-right-radius: 20px;
        padding: 20px;
        margin-bottom: 100px;
        
        a{
          font-size: 18px;
          text-transform: uppercase;
          font-family: $text;
          font-weight: map-get($weight, -extrabold);
          text-decoration: none;
          color: #00607A;
        }
      }
    }
  }
  
}


/* Queries */
@media (max-width:(map-get($media , -notebookS))){
  .servicios{
    &-cont{
      .items-cont{
        max-width: 95%;
        .item{
          padding: 25px;
          .cont-info{
            gap: 30px;
            img{
              width: 200px;
              height: 200px;
            }
            p{
              font-size: 18px;
            }
          }
        }
      }
    }
  }
}


@media (max-width:(map-get($media , -tabletS))){
  .servicios{
    &-cont{
      .items-cont{
        .item{
          padding: 25px 10px;
          .cont-info{
            flex-wrap: wrap;
            gap: 50px;
            img{
              margin-bottom: 0px;
            }
            h5{
              text-align: center;
            }
            p{
              text-align: center;
            }
            .btn-verMas{
              margin: 20px;
            }
          }
        }
      }
    }
  }
}



@media (max-width:(map-get($media , -mobile))){
  .servicios{
    &-cont{
      .title-cont{
        @include flex(center);
        @include title('-secundario','-terciario','Mis Servicios',100%);
      }
   
    }
  }
}