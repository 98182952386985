@use '../abstracts/mixin.scss' as *;
@use '../abstracts/variables.scss' as *;

.svg-diagonal{
  margin-top: 200px
}

.logo-section{
  @include flex(center);
  background-color: map-get($colores, -principal);
  .logo-cont{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .title-cont{
      @include title('-principal','-terciario','¿Cómo llegué al logo y al nombre?',120%);
    }

    .img-cont{
      @include flex(center);
      margin-top: 180px;
      width: 300px;
      height: 300px;
      text-align: center;
      background-color: map-get($colores, -principal2);
      border-radius: 50%;
      img{
        width: 170px;
        height: auto;
      }
    }

    .text-cont{
      display: block;
      max-width: 60%;
      margin: 50px auto auto auto;
      p{
        font-size: map-get($size , -md);
        font-family: $text;
        font-weight: map-get($weight, -medium);
        color: map-get($colores , -terciario);
        margin: 50px 0 !important;
      }
    }
  }
}


/* Queries */
@media (max-width:(map-get($media , -tablet))){
  .logo-section{
    .logo-cont{
      .title-cont{
        .title-logo{
          padding-bottom: 50px !important;
        }
      }

      .text-cont{
        max-width: 80%;
      }

      .img-cont{
        @include flex(center);
        margin-top: 180px;
        width: 200px;
        height: 200px;
        img{
          width: 130px;
          height: auto;
        }
      }
    }
  }
}


@media (max-width:(map-get($media , -tabletS))){
  .logo-section{
    .logo-cont{
      .title-cont{
        @include title('-principal','-terciario','Como llegue al logo y al nombre',100%);
      }
    }
  }
}