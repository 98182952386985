@use '../abstracts/mixin.scss' as *;
@use '../abstracts/variables.scss' as *;


.nav{
  @include flex (center);
  position: fixed;
  width: 100%;
  z-index: 11; 
  background-color: map-get($colores, -principal);
  padding: 10px 0;
  transition: padding .3s ease;

  &.scroll{
    padding: 20px 0;
  }
  
  &-cont{
    @include flex (space-between);
    gap: 50rem;
  }

  .cont-logo{
    width: 160px;
    height: 150px;
    text-align: center;
    background-color: map-get($colores, -principal2);
    border-radius: 50%;
    img{
      width: 130px;
      height: auto;
    }
  }


  &-ul{
    display: flex;
    gap: 50px;
  }

  .nav-li{
    font-size: map-get($size, -md);
    font-weight: map-get($weight , -bold);
    font-family: $text;
    cursor: pointer;

    a{
      color: map-get($colores, -terciario);
    }

    a:after{
      content: '';
      height: 5px;
      width: 0%;
      background-color: map-get($colores , -terciario);
      display: block;
      transition: width 0.3s ease;
    }


    &:hover a:after{
      width: 30%;
    }

    &.active a::after{
      width: 100%;
    }

  }

  .nav-mobile{
    display: none;
  }


}

.offcanvas-background,
.menu-offcanvas{
  display: none;
}








/* Queries */
@media (max-width:(map-get($media , -pc))){
  .nav{

    &-cont{
      gap: 10rem;
    }
  }
}


@media (max-width:(map-get($media , -tablet))){
  .nav{
    display: block !important;
    &-cont{
      @include flex (space-between);
      padding: 0 30px;
      .nav-mobile{
        display: block;
        z-index: 2;
        background-color: map-get($colores ,-secundario);
        border: 4px solid map-get($colores ,-principal2);
        padding: 5px;

        .nav-icon{
          position: relative;
          display: flex;
          justify-content: center;
          align-items: center;
          overflow: hidden;
          margin: auto;
          width: 35px;
          height: 25px;
          transition: all 1s ease;

          span{
            position: absolute;
            width: 80%;
            height: 5px;
            background-color: map-get($colores ,-principal2);
            border-radius: 10px;
            transition: all .3s ease; 
        
          }

          span:nth-child(1){
            top: 0;
            transform: translateY(0);
          }

          span:nth-child(3){
            bottom: 0;
            transform: translateY(0);
          }
        
        }

        .nav-btn{

          &.active span:nth-child(1){

            transform: translateY(10px);
            transform: rotate(-50deg);
            top: 10px;
          }

          &.active span:nth-child(2){
            transform: translateX(-50px);
          }

          &.active span:nth-child(3){
    
            transform: translateY(10px);
            transform: rotate(50deg);
            bottom: 10px;
          }
        }
      }
      .nav-ul{
        display: none;
      }
    }
  }




  .offcanvas-background{
    display: block;
    transition: background-color .2s ease;

    &.active{
      position: fixed;
      top: 0;
      left: 0;
      z-index: 3;
      width: 100vw;
      height: 100vh;
      background-color: #00000061;
    }
  }    


  .menu-offcanvas{
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    height: 100vh;
    width: 40%;
    left: -100%;
    top: 0;
    background: map-get($colores, -secundario);
    text-align: center;
    padding-top: 70px;
    z-index: 5;
    transition: all 0.5s ease-in-out;

    .nav-list{
      display: grid;
      justify-content: center;
      align-items: center;
      gap: 50px;

      .nav-li{
        font-size: map-get($size, -md);
        font-weight: map-get($weight , -extrabold);
        font-family: $text;
        cursor: pointer;
    
        a{
          color: map-get($colores, -principal);
        }
    
        a:after{
          content: '';
          height: 5px;
          width: 0%;
          background-color: map-get($colores , -principal);
          display: block;
          transition: width 0.3s ease;
        }
    
    
        &:hover a:after{
          width: 30%;
        }
    
        &.active a::after{
          width: 100%;
        }
      }
    }
  }
}

@media (max-width:(map-get($media , -mobile))){
  .nav{
    .cont-logo{
      width: 130px;
      height: 130px;
      img{
        width: 100px;
        height: auto;
      }
    }
  }
  .menu-offcanvas{
    width: 60%;
  }
    
}


@media (max-width:(map-get($media , -mobileS))){
  .nav{
    .cont-logo{
      width: 115px;
      height: 115px;
      img{
        width: 90px;
        height: auto;
      }
    }
    &-cont{
      padding: 0 10px;
    }
  }
}
