*{
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

li{
  list-style: none;
}

a{
  text-decoration: none !important;
}

//Reset Bootstrap
h1,h2,h3,p{
  margin: 0 !important;
}