@use '../abstracts/variables.scss' as *;
@use '../abstracts/mixin.scss' as *;

.footer{
  margin-top: 250px;

  &-cont{
    @include flex(space-around);
    background-color: map-get($colores, -principal);
    padding: 15px;

    .img-cont{
      width: 140px;
      height: 130px;
      text-align: center;
      background-color: map-get($colores, -principal2);
      border-radius: 50%;
      img{
        width: 110px;
        height: auto;
      }
    }

    .by-info{

      p{
        font-size: map-get($size , -sm) !important;
        font-family: $text;
        font-weight: map-get($weight, -bold);
        color: map-get($colores , -terciario);
        letter-spacing: 5px;
        a{
          font-family: $title;
          font-size: 18px;
          font-weight: map-get($weight, -extrabold);
          color: map-get($colores , -terciario);
          margin: 0 8px;
        }
      }
    }

    .social-media{
      @include flex(space-around);
      gap: 50px;
      i{
        color: map-get($colores , -terciario);
        font-size: map-get($size , -lg);
        background-color: map-get($colores , -principal2);
        padding: 10px;
        border-radius: 50%;
        transition: color .3s ease,
        background-color .3s ease,
        border-radius .3s ease;
        cursor: pointer;

        &:hover{
          color: map-get($colores , -principal2);
          background-color: map-get($colores , -terciario);
          border-radius: 30%;
        }
      }
    }
  }
}



/* Queries */
@media (max-width:(map-get($media , -notebook))){
  .footer{
    &-cont{
      .social-media{
        gap: 30px;
      }
    }
  }
  
}


@media (max-width:(map-get($media , -notebookS))){
  .footer{
  
    &-cont{
      @include flex(space-between);
      padding: 15px 10px;
  
      .img-cont{
        width: 130px;
        height: 120px;
        img{
          width: 100px;
          height: 100px;
        }
      }
      .social-media{
        gap: 15px;

        i{
          font-size: map-get($size, -md);
        }
      }
    }
  }
  
}


@media (max-width:(map-get($media , -tablet))){
  .footer{
    &-cont{
      display: grid;
      justify-content: center;
      align-content: center;
      text-align: center;
      gap: 30px;

      .img-cont{
        margin: auto;
      }

      .social-media{
        display: block;

        i{
          margin: 0 20px;
        }
      }
    }
  }
  
}



@media (max-width:(map-get($media , -mobileS))){
  .footer{
    &-cont{
      .img-cont{
        width: 115px;
        height: 115px;
        img{
          width: 90px;
          height: auto;
        }
      }
    }
  }
}