@use '../abstracts/variables.scss' as *;

.cont-btnScroll{
  position: fixed; 
  height: 45px;
  width: 42px;
  background: var(--color5);
  right: 20px;
  bottom: 10px;
  text-align: center;
  line-height: 45px;
  color: #fff;
  z-index: 900;
  font-size: 30px;
  border-radius: 50px;
  cursor: pointer;
  opacity: 0;
  pointer-events: none;
  transition: all .3s ease;
  
  i{
    font-size: map-get($size, -lg);
    color: map-get($colores, -secundario);
    padding: 10px;
    border-radius: 50%;
    background-color: map-get($colores, -terciario);;
  }

  &.active{
    opacity: 0.8;
    pointer-events: auto;
    bottom: 30px;
  }
}


/* Queries */
@media (max-width:(map-get($media , -tabletS))){
  .cont-btnScroll{
    right: 8px;
    
    i{
      font-size: map-get($size, -md);
      padding: 8px;
    }
  }
}