@use '../abstracts/variables.scss' as *;

.header{
  overflow: hidden;

  .cont-imgHome{
    height: 100vh;
    background-image:url('../../img-src/img-home.png');
    background-attachment: fixed;
    background-size: cover;
    background-repeat:no-repeat;
    filter: blur(4px);
  }


  

  .cont-title{
    background-color: rgb(0,0,0);
    background-color: rgba(0,0,0, 0.4);
    font-weight: bold;
    border: 3px solid #f1f1f1;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 0;
    width: 80%;
    padding: 20px;
    text-align: center;

      .title-home{
        font-size: map-get($size , -xxl);
        color: #DB00B6;
        -webkit-text-stroke:1.5px map-get($colores, -terciario) ;
        font-family: $title;
        font-weight: map-get($weight, -medium);
      }
  }
}



/* Queries */
@media (max-width:(map-get($media , -notebook))){
  .header{
    .cont-title{
      .title-home{
        font-size: map-get($size , -xl);
        -webkit-text-stroke:1px map-get($colores, -terciario) ;

      }
    }
  }
}


@media (max-width:(map-get($media , -notebookS))){
  .header{
    .cont-title{
      width: 90%;
      padding: 50px 20px;
    }
  }
  
}



@media (max-width:(map-get($media , -tabletS))){

  .header{
    .cont-title{
      width: 95%;
      padding: 80px 10px;
      .title-home{
        font-size: map-get($size , -lg);
        -webkit-text-stroke:0.5px map-get($colores, -terciario) ;
      }
    }
  }
}