@use '../abstracts/mixin.scss' as *;
@use '../abstracts/variables.scss' as *;

.canalizaciones{
  @include flex(center);
  
  .canalizaciones-cont{

    .title-cont{
      @include flex(center);
      @include title('-secundario','-terciario','Espirituales',120%);
    }
    
    .explicacion-can{
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      .text-exp{
        margin-top: 100px;
        display: block;
        max-width: 60%;
        p{
          font-size: map-get($size , -md);
          font-family: $text;
          font-weight: map-get($weight, -medium);
          color: map-get($colores , -terciario);
          margin: 50px 0 !important;
        }
      }
    }

    .mis-canalizaciones{
      .title-misCan{
        margin-top: 200px;
        h2{
          font-size: map-get($size, -xl);
          font-family: $text;
          font-weight: map-get($weight, -extrabold);
          color: map-get($colores, -terciario);
          letter-spacing: 5px;
          text-align: center;
        }
      }

      .cont-items{
        display: block;
        max-width: 60%;
        margin: auto;

        .item{
          background-color: #FAE1DD;
          padding: 100px;
          margin: 100px 0;
          border-radius: 20px;

          .icon-comillas{
            margin-bottom: 30px;
            i{
              font-size: map-get($size, -xxl);
              font-weight: map-get($weight, -extrabold);
              color: map-get($colores, -terciario);
            }
          }

          .desc-cont{
            p{
              font-size: map-get($size , -md);
              font-family: $text;
              font-weight: map-get($weight, -medium);
              color: map-get($colores , -terciario);
              margin: 50px 0 !important;
            }

            .cancion{
              font-size: map-get($size , -md);
              font-family: $text;
              font-weight: map-get($weight, -medium);
              color: map-get($colores , -terciario);
              span{
                display: block
              }
            }
          }
        }
      }

    }
    .simbolo-frase{
      display: block;
      max-width: 90%;
      margin: auto;
      .cont-simbolo-frase{
        @include flex(space-evenly);
        .cont-frase{
          text-align: center;
          h4{
            font-size: map-get($size , -md);
            font-family: $text;
            font-weight: map-get($weight, -extrabold);
            color: map-get($colores , -terciario);
          }
        }
        
      }
    }
  }
}




/* Queries */

@media (max-width:(map-get($media , -notebook))){
  .canalizaciones{
    .canalizaciones-cont{
      .cont-simbolo-frase{
        img{
          width: 350px;
          height: auto;
        } 
      }
    }
  }
}



@media (max-width:(map-get($media , -notebookS))){
  .canalizaciones{
    .canalizaciones-cont{
      .mis-canalizaciones{
        .cont-items{
          max-width: 90%;
        }
      }
    }
  }
}


@media (max-width:(map-get($media , -tablet))){
  .canalizaciones{
    .canalizaciones-cont{

      .explicacion-can{
        .text-exp{

          max-width: 80%;
          p{
            margin: 20px 0 !important;
          }
        }
      }

      .mis-canalizaciones{
        .cont-items{
          .item{
            padding: 50px;
          }
        }
      }
    }
  }
}


@media (max-width:(map-get($media , -tabletS))){
  .canalizaciones{
    .canalizaciones-cont{
      .title-cont{
        @include title('-secundario','-terciario','Espirituales',100%);
      }

      .simbolo-frase{
        max-width: 100%;
        .cont-simbolo-frase{
          flex-direction: column;
          img{
            width: 250px;
            margin-bottom: 50px;
          }
        }
      }
    }
  }
}


@media (max-width:(map-get($media , -mobile))){
  .canalizaciones{
    .canalizaciones-cont{
      .mis-canalizaciones{
        .title-misCan{
          h2{
            font-size: map-get($size, -lg);
          }
        }
      }
    }
  }
}